import {
  USER_TENANTS,
  SMART_IS_EMAIL,
  AUTH_PERMISSION,
  USER_DATA,
  ROLES,
  DEFAULT_TENANT,
} from "../../constants/applicationConstants";
export const ValidatePermissions = (item) => {
  const userTenants = JSON.parse(localStorage.getItem(USER_TENANTS));
  let filterTenants = userTenants.filter(
    (userTenant) =>
      (userTenant.TenantId.toLowerCase() === item?.tenant_id?.toLowerCase() ||
        userTenant.TenantId.toLowerCase() === item?.TenantId?.toLowerCase() ||
        userTenant.TenantId.toLowerCase() === item?.tenantId?.toLowerCase()) &&
      userTenant.IsWrite === true
  );
  if (filterTenants.length > 0) {
    return true;
  }
  return false;
};
export const findTenant = (item) => {
  let tempTenant = JSON.parse(localStorage.getItem(USER_TENANTS)).filter(
    (userTenant) =>
      userTenant.TenantId.toLowerCase() === item?.tenant_id?.toLowerCase() ||
      userTenant.TenantId.toLowerCase() === item?.TenantId?.toLowerCase() ||
      userTenant.TenantId.toLowerCase() === item?.tenantId?.toLowerCase()
  )[0];
  return tempTenant;
};
/**
 * check Feature Subscription
 * @param {Array} products
 * @param {String} feature_name
 * @returns {boolean}
 */
export const checkFeatureSubscription = (products, feature_name) => {
  return products?.some((product) => {
    return product?.Listfeaturevm?.some((feature) => {
      return feature.name === feature_name && !feature.Issubscribed;
    });
  });
};
/**
 * is Company Admin Role
 * @param {Object} user
 * @returns {boolean}
 */
export const hasUserRole = (user, role) => {
  const rolesArray = user?.roles
    .split(",")
    .map((roleItem) => roleItem.split(":")[0].trim());
  return rolesArray?.includes(role);
};

/**
 * is smart is email
 * @param {Object} user
 * @returns {boolean}
 */
export const isSmartIsEmail = (obj) => {
  if (obj && typeof obj.name === "string") {
    return obj.name.toLowerCase().includes(SMART_IS_EMAIL);
  }
  return false;
};

/**
 * checkParentRoutePermission
 * @param {Object} obj
 * @param {Array} routes
 * @returns {boolean}
 */
export const checkParentRoutePermission = (obj, routes) => {
  if (obj?.parentid) {
    const matchingObject = routes.find((temp) => temp.id === obj?.parentid);
    if (
      matchingObject &&
      matchingObject?.AuthPermission &&
      AUTH_PERMISSION[matchingObject?.AuthPermission] &&
      AUTH_PERMISSION[matchingObject?.AuthPermission] ==
        ROLES[matchingObject?.AuthPermission] &&
      !hasUserRole(
        JSON.parse(sessionStorage.getItem(USER_DATA)),
        ROLES[matchingObject?.AuthPermission]
      )
    ) {
      return false;
    }
  }

  return true;
};
/**
 * getDefaultTenant
 * @returns {object}
 */
export const getDefaultTenant = () => {
  return JSON.parse(localStorage.getItem(DEFAULT_TENANT));
};
export default ValidatePermissions;
